<template>
  <div>

    <div class="page-heading relative">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-content">
            <div class="heading-title">
              <div class="header-card-detail">
                <div class="header-card-brand">
                  <img v-if="card.card.brand" :src="`/assets/images/cards/${card.card.brand}.png`" style="margin-top: 10px;"/>
                </div>
                <h2>{{card.card.name}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="input-group pull-right" style="width: auto;">
        <div class="input-group-append drop-down arrow-center black" style="float: right; width: auto;">
          <a class="btn btn-default btn-sm ml-5 inherit btn-heading btn-account-details" type="button" @click="$router.go(-1)"><span><i class="mdi mdi-arrow-left"></i></span> Voltar</a>
          <a class="btn btn-warning btn-sm ml-5 inherit btn-heading btn-account-details" style="margin-top: 0px; margin-left: 5px;" type="button" @click="editCard('edit-card-modal')"><span><i class="fa fa-edit"></i></span></a>
          <a class="btn btn-danger btn-sm ml-5 inherit btn-heading btn-account-details" style="margin-top: 0px; margin-left: 5px;" type="button" @click="removeCard"><span><i class="fa fa-trash"></i></span></a>
          <a class="btn btn-primary btn-sm ml-5 inherit btn-heading btn-account-details" style="margin-top: 0px; margin-left: 5px;" type="button" v-if="card.invoice.status != 4 && card.invoice.status != 1" @click="payInvoice('create-transaction-modal', card.invoice.value)"><span><i class="fas fa-check"></i></span> Pagar</a>
          
        </div>
      </div>
      
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="owl-nav" style="position: relative; width: 35%; top: 0; margin: 0 auto; margin-top:20px;margin-bottom:23px;">
          <calendar-co :month="monthStart" :year="yearStart" :callBack="changeDate" :navigateTo="navigateDateTo"></calendar-co>
        </div>
      </div>
      <div class="ol-lg-8 col-md-8 col-sm-8">
        <div class="card" style="padding-bottom: 0px; padding-top: 0px;">
          <div class="card-body relative">
              <div class="transaction-progress" style="width: 70%;">
                <div class="item mt-5">
                  <span class="pull-right ng-binding">Limite disponível: {{card.card.available | currency }}</span>
                  <p class="text-muted"><i class="mdi mdi-checkbox-blank-circle-outline" :class="{'text-danger': card.card.progress > 85, 'text-warning': card.card.progress > 70 &&  card.card.progress < 85, 'text-primary': card.card.progress < 70 }"></i> Limite usado</p>
                  <div class="progress progress-bar-success-alt">
                    <div role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" class="progress-bar" :style="`width: ${card.card.progress}%;`" :class="{'progress-bar-danger': card.card.progress > 85, 'progress-bar-warning': card.card.progress > 70 && card.card.progress < 85, 'progress-bar-success': card.card.progress < 70 }" >
                    </div>
                  </div>
                  <h4 class="reports-expenses text-left font-w-100 ng-binding" style="margin-bottom:25px; display:block;">{{card.card.spent | currency }} de {{card.card.card_limit | currency }}</h4>
                </div>
              </div>
              <div></div>
              <img :src="`/assets/images/credit.png`" style="opacity: 0.2; margin-top: 13px; width: 13% !important;" class="nothing-image w-15p right-transaction">
          </div>
        </div>
      </div>
      <div class="ol-lg-4 col-md-4 col-sm-4">
        <div class="card">
          <div class="card-body" style="padding: 0;">
            <div class="chart-insights text-left">
              <p>Fechamento</p>
              <h4 style="margin: 0px;font-size: 18px; color: #5e5e5e;">
                <strong class="font-w-400">
                  {{ card.card.invoice_date | moment("DD") }} de {{ card.card.invoice_date | moment("MMMM") }}
                </strong>
              </h4>
            </div>
            <div class="chart-insights text-left" style="margin-top: 5px;">
              <p>Vencimento</p>
              <h4 style="margin: 0px;font-size: 18px; color: #5e5e5e;">
                <strong class="font-w-400">
                  {{ card.card.payment_date | moment("DD") }} de {{ card.card.payment_date | moment("MMMM") }}
                </strong>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content relative" style="overflow: hidden;">
          <div class="group-flag">
            <p class="flag bill-open ng-scope" v-if="card.invoice.status == 0">Fatura aberta</p>
            <p class="flag bill-paid ng-scope" v-if="card.invoice.status == 1">Fatura paga</p>
            <p class="flag bill-closed ng-scope" v-if="card.invoice.status == 2">Fatura fechada</p>
            <p class="flag bill-due ng-scope" v-if="card.invoice.status == 3">Fatura vencida</p>
            <p class="flag bill-no ng-scope" v-if="card.invoice.status == 4">Fatura zerada</p>
            
          </div>
          <div class="tab-pane printable show">
            <div class="card table-responsive longer radius-10">
              <div class="m-bottom-0 relative">
                <div class="m-bottom-0">
                    <section class="text-center">
                      <h3 class="text-left font-w-100">Valor da fatura <br><strong class="total-amount">{{card.invoice.value | currency}}</strong></h3>
                    </section>
                </div>
                <span class="label label-success info-label-card" style="top: 10px !important" v-if="card.card.automatic">Automática</span>     
                <span class="info-label-card" style="top: 30px !important; font-size: 13px;" v-if="card.card.automatic">Atualizado em: {{ card.card.last_sync | moment("DD/MM/YYYY H:mm") }}</span>     
              </div>
              <div class="row" v-if="card.paid.transactions.length > 0">
                <div class="col-sm-12"><hr></div>
                <div class="col-sm-10 col-md-10 col-lg-10">
                  <ul class="list-group">
                    <li class="list-group-item ng-scope list-item-pay" v-for="(item, i) in card.paid.transactions" :key="i">
                      <div class="row">
                        <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4">
                          Valor pago
                        </div>
                        <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-4">
                          Conta
                        </div>
                        <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                          Data
                        </div>
                        <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2 text-right">
                        
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-6">
                          <span class="text-success"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                        </div>
                        <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-4 col-xs-6">
                          <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;">
                            {{item.account.name}}
                          </span>
                        </div>
                        <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                          <span class="ng-binding">{{ item.transaction_date | moment("DD/MM/YYYY") }}</span>
                        </div>
                        <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2 text-right">
                          <button type="button" class="btn-remove-category" @click="removeTransaction(item)">
                            <span>
                              <i class="fa fa-trash"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-sm-2 col-md-2 col-lg-2">
                  <div class="card-body" style="padding: 0px;">
                    <div class="chart-insights text-left">
                      <p>Valor total pago</p>
                      <h4 style="margin: 0px; font-size: 18px; color: rgb(94, 94, 94);">
                        <strong class="font-w-400"> {{card.paid.total | currency }} </strong></h4>
                    </div>
                    <div class="chart-insights text-left" style="margin-top: 5px;">
                      <p>Valor restante</p>
                      <h4 style="margin: 0px; font-size: 18px; color: rgb(94, 94, 94);">
                        <strong data-v-6b3b068a="" class="font-w-400"> {{card.paid.available | currency }} </strong>
                      </h4>
                    </div>
                    <div class="chart-insights text-left" v-if="card.paid.available > 0" style="margin-top: 5px;">
                      <span class="label label-info c-pointer" @click="payInvoice('create-transaction-modal', card.paid.available)">Pagar restante</span>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div>
                <h3 class="display-4" style="float:left;margin-top: 0px;">Lançamentos</h3>
                <div style="float: right;" class="drop-down arrow-center black">
                  <button class="drop-down-transaction btn btn-green btn-add-transaction color-white btn-green-shadow" style="height: 35px;">
                    <span class="to-left">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 20 20"><path data-v-021887fb="" fill-rule="evenodd" d="M10.833 9.167V5H9.167v4.167H5v1.666h4.167V15h1.666v-4.167H15V9.167h-4.167zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path></svg>
                    </span>
                    <div class="drop-down-box" style="display: none;">
                      <ul>
                        <li class="expense c-pointer" @click="createTransaction('create-transaction-modal')"><a >Despesa</a></li>
                        <li class="revenue c-pointer" @click="createRefund('create-transaction-modal')"><a >Reembolso</a></li>
                      </ul>
                    </div>
                  </button>
                </div>
                <div class="cashflow-print" @click="print" style="float:right;"><i class="fa fa-print hide"></i></div>
                
                <div style="clear:both;"></div>
              </div>
              <div class="row  hidden-xs" v-if="card.transactions.length > 0">
                <div class="col-sm-4 col-lg-4 col-xs-4">
                  Valor
                </div>
                <div class="col-sm-4 col-lg-4 col-xs-4">
                  Categoria
                </div>
                <div class="col-sm-2 col-lg-2 col-xs-2 text-center">
                  Data da transação
                </div>
                <div class="col-sm-2 col-lg-2 col-xs-2 text-right">
                </div>
              </div>
              <hr>
              <div class="row ng-scope" v-if="card.transactions.length == 0">
                <img :src="`/assets/images/report.svg`" class="nothing-image w-15p">
                <h3 class="nothing-text">Nenhuma lançamento encontrado</h3>
              </div>
              <ul class="list-group" v-if="card.transactions">
                <li class="list-group-item ng-scope" v-for="(item, i) in card.transactions" :key="i">
                  <div class="row">
                    <div class="col-sm-4 col-lg-4 col-xs-6">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-4 col-lg-4 col-xs-12 text-right">
                      <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;">
                        <div class="icon-category-overview" :style="`margin-right:0px;background: ${item.category.color}`">
                          <img :src="`/assets/images/icons/png/${item.category.icon}.png`" />
                        </div>
                        &nbsp;{{item.category.name}}
                      </span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12 text-right">
                      <span class="ng-binding">{{ item.transaction_date | moment("DD/MM/YYYY") }}</span>
                    </div>
                    <div class="col-sm-2 col-lg-2 col-xs-12 text-right">
                      <button type="button" class="btn-edit-category" @click="editTransaction('edit-transaction-modal', item)">
                        <span>
                          <i class="fa fa-cog"></i>
                        </span>
                      </button>
                      <button type="button" class="btn-remove-category" @click="removeTransaction(item)">
                        <span>
                          <i class="fa fa-trash"></i>
                        </span>
                      </button>
                    </div>
                    <div class="col-sm-12 relative" v-if="item.description || item.automatic">
                      <hr class="hr-transactions">
                      <span class="ng-binding transaction-note">{{item.description }}</span>
                      <span class="label label-success " style="right: 15px; top: 15px; font-size: 10px; font-weight: 100; !important; position: absolute;" v-if="item.automatic">Automática</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h4 class="display-4 text-right">Rembolsos: <span class="reports-income">{{card.refund | currency}}</span></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <edit-card-modal id="edit-card-modal" @save="request"/>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import EditCardModal from '@/components/cards/EditCardModal.vue';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import CalendarCo from '../components/Calendar.vue';
import Swal from 'sweetalert2';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: 'Home',
  components: {
    CalendarCo,
    EditCardModal,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      card: {
        card: {
          account: {},
        },
        invoice: {},
        account: {},
        transactions: [],
        paid: {
          transactions: [],
        },
      },
      monthStart: 0,
      yearStart: 2023,
      from: '',
      to: '',
      isLoading: true,
    };
  },
  provide: {
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
    moment.locale('pt_BR');
    this.yearStart = Number(moment(new Date()).format('YYYY'));
    this.monthStart = new Date().getMonth();
    console.log(this.yearStart);
    console.log('Mês', this.monthStart);
    pace.start();

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to   = moment(this.from, "YYYY-MM-DD").endOf('month');

    this.request();
    this.$root.$on('register.transaction', this.request);
  },
  methods: {
    removeCard() {
      Swal.fire({
        title: `Remover cartão?`,
        text: 'Tem certeza que deseja remover esse cartão?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteCard();
        }
      });
    },
    deleteCard() {
      AppService.deleteCard(this.card.card.id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Cartão removido!",
            type: "success",
          });
          this.$router.go(-1);
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    editTransaction(id, item) {
      let data      = item;
      data.date     = moment(data.transaction_date, "YYYY-MM-DD").format('DD/MM/YYYY');
      data.isCard   = true;
      data.value    = item.amount;
      data.note     = item.description;
      data.card     = item.card;
      data.category = item.category;
      data.invoice_pay = false;
      data.invoice     = this.card.invoice;
      data.parent   = item.category.parent;
      console.log('Edit transaction');
      console.log(data);
      this.$root.$emit('show.modal', id, data);
    },
    deleteTransaction(id) {
      AppService.deleteTransaction(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Transação removida!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeTransaction(item) {
      Swal.fire({
        title: `Remover transação?`,
        text: 'Tem certeza que deseja remover essa transação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteTransaction(item.id);
        }
      });
    },
    editCard(id) {
      this.$root.$emit('show.modal', id, this.card.card);
    },
    createTransaction(id) {
      const data = {
        isCard: true,
        type: 'expense',
        card: this.card.card,
        bill_type: {
          type: 'P',
        },
        is_recurrence: false,
        invoice_pay: false,
        invoice: this.card.invoice,
        installments: 1,
        account: null,
        check_sub: true,
        date: moment(new Date()).format('DD/MM/YYYY'),
      };
      this.$root.$emit('show.modal', id, data);
    },
    createRefund(id) {
      const data = {
        note: `Reembolso fatura de ${moment(this.card.invoice.date).format('MMMM')} - ${this.card.card.name}`,
        isCard: true,
        type: 'income',
        card: this.card.card,
        bill_type: {
          type: 'M',
        },
        is_recurrence: false,
        invoice_pay: false,
        invoice_refund: true,
        invoice: this.card.invoice,
        account: null,
        date: moment(new Date()).format('DD/MM/YYYY'),
      };
      this.$root.$emit('show.modal', id, data);
    },
    payInvoice(id, value) {
      const data = {
        type: 'expense',
        note: `Fatura de ${moment(this.card.invoice.date).format('MMMM')} - ${this.card.card.name}`,
        card: this.card.card,
        bill_type: {
          type: 'M',
        },
        is_recurrence: false,
        invoice: this.card.invoice,
        account: this.card.card.account ? this.card.card.account.id : null,
        invoice_pay: true,
        isCard: true,
        value: value,
        date: moment(new Date()).format('DD/MM/YYYY'),
      };
      this.$root.$emit('show.modal', id, data);
    },
    navigateDateTo (month) {
      console.log(month);
      this.from = `${month.year}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      this.request();
    },
    changeDate(date) {
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    request() {
      pace.start();
      AppService.getInvoice({
        from: this.from,
        to: this.to,
        card_id: this.$route.params.id,
      }).then(
        (response) => {
          console.log('Invoice');
          console.log(response);
          this.card = response;
          this.isLoadingCards = false;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    print () {
      window.print();
    },
  },
};
</script>

<style scoped lang="scss">
.display-4 {
  color: #8a8a8a;
  font-weight: 10;
}
.btn-add-transaction {
  height: 35px;
  float: right;
  padding-top: 6px !important;
}
.header-card-detail .header-card-brand{
  width: 60px;
  height: 60px;
  background: #eee;
  border-radius: 50%;
  padding: 5px;
}
.heading-content {
  width: 70%;
}
.header-card-detail {
  width: 100%;
}
.header-card-detail .header-card-brand img{
  width: 100%;
}
.header-card-detail .header-card-brand {
  float: left;
}
.header-card-detail h2{
  float: left !important;
  padding-left: 13px !important;
  padding-top: 5px !important;
  font-weight: 100 !important;
}
.chart-insights p {
  margin-top: 0px;
}
.cashflow-print {
  position: absolute;
  right: 110px;
  margin-right: 10px;
  margin-top: 8px;
  cursor: pointer;
}
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 200px;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}
.btn-remove-filter i {
  margin-right: 0;
}
.right-transaction{
  position: absolute;
  right: 0;
  top: 0;
}
.credit-card-shadow{
  box-shadow:   0px 2px 1px -1px rgba(159, 159, 159, 0.2), 0px 1px 1px 0px rgba(140, 140, 140, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.group-flag {
  position: absolute;
  right: -60px;
}
.group-flag .flag.bill-paid {
  background-color: #1cd373;
}
.group-flag .flag.bill-closed {
  background-color: #ec971f;
}
.group-flag .flag.bill-due {
  background-color: #ff1a1a;
}
.group-flag .flag.bill-open {
  background-color: #007bff;
}
.group-flag .flag.bill-no {
  background-color: #939393;
}
.group-flag .flag {
  color: #fff;
  padding: 10px;
  -webkit-transform: rotate(50deg);
  -moz-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
  -o-transform: rotate(50deg);
  transform: rotate(50deg);
  margin-top: 35px;
  margin-left: -85px;
  width: 200px;
  right: 10px;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
}
.list-item-pay {
  border: solid 1px #17d06e;
  background: #f8fffb;
}
.btn-edit-category {
  color: #12c48b;
  background-color: hsl(160, 60%, 94%);
  position: relative;
  height: 34px;
  line-height: 34px;
  width: 30%;
  padding: 0 8px;
  border-radius: 4px;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  border: none;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: background-color .2s ease-in-out;
  -o-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
}
.btn-edit-category:hover {
  background-color: #acd9ca;
}
.btn-remove-category:hover {
  background-color: #f0d2d2;
}
.btn-edit-category span {
  display: inline-block;
  position: relative;
  top: -1px;
  color: #12c48b;
  text-align: center;
}

.btn-remove-category {
  color: #f14c52;
  background-color: #feefef;
  position: relative;
  height: 34px;
  line-height: 34px;
  width: 30%;
  padding: 0 8px;
  border-radius: 4px;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  border: none;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-transition: background-color .2s ease-in-out;
  -o-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;
  margin-left: 10px;
}
.btn-remove-category span {
  display: inline-block;
  position: relative;
  top: -1px;
  color: #f14c52;
  text-align: center;
}
.drop-down {
 position:relative
}
.drop-down.arrow-left .drop-down-box:after {
 left:20px;
 right:initial
}
.drop-down.arrow-center .drop-down-box:after {
 left:64%;
 right:initial;
 margin-left:-6px
}
.drop-down.arrow-right .drop-down-box:after {
 right:20px;
 left:initial
}
.drop-down .drop-down-box {
 display:none;
 position:absolute;
 margin-top:4px;
 left: -35px;
 top: 33px;
 background:#fff;
 -moz-box-shadow:0 1px 6px rgba(0,0,0,.17);
 -webkit-box-shadow:0 1px 6px rgba(0,0,0,.17);
 box-shadow:0 1px 6px rgba(0,0,0,.17);
 width: 120px;
 z-index:2;
 -moz-border-radius:2px;
 -webkit-border-radius:2px;
 border-radius:2px
}
.drop-down .drop-down-box .scroll-group>ul,
.drop-down .drop-down-box ul {
 padding:12px 0;
 list-style: none;
}
.drop-down .drop-down-box .scroll-group>ul li a,
.drop-down .drop-down-box ul li a {
 padding:6px 30px;
 font-size:14px;
 display:block;
 color:#777;
 text-align:left;
 letter-spacing:0;
 -webkit-transition:all .15s ease-out 0s;
 -moz-transition:all .15s ease-out 0s;
 -o-transition:all .15s ease-out 0s;
 transition:all .15s ease-out 0s
}
.drop-down .drop-down-box .scroll-group>ul li a:hover,
.drop-down .drop-down-box ul li a:hover {
 color:#0a914b
}
.drop-down .drop-down-box .scroll-group>ul li.active>a,
.drop-down .drop-down-box ul li.active>a {
 color:#0a914b
}
.drop-down .drop-down-box .scroll-group>ul li:last-child,
.drop-down .drop-down-box ul li:last-child {
 border-bottom:none
}
.drop-down .drop-down-box .scroll-group>ul li.logout a:hover,
.drop-down .drop-down-box ul li.logout a:hover {
 color:#ff7d78
}
.drop-down .drop-down-box .scroll-group>ul li ul,
.drop-down .drop-down-box ul li ul {
 padding:0
}
.drop-down .drop-down-box .scroll-group>ul li ul li a,
.drop-down .drop-down-box ul li ul li a {
 padding:6px 30px 6px 45px
}
.drop-down .drop-down-box .actions {
 display:block;
 padding:0 20px 20px
}
.drop-down .drop-down-box .actions:after {
 content:".";
 display:block;
 height:0;
 clear:both;
 visibility:hidden
}
* html .drop-down .drop-down-box .actions {
 height:1px
}
.drop-down .drop-down-box .actions a {
 float:left;
 margin-right:11px;
 padding:0 12px 0 0;
 border-right:1px solid #8d979b;
 text-transform:lowercase;
 font-size:13px
}
.drop-down .drop-down-box .actions a:last-child {
 margin-right:0;
 padding:0;
 border-right:none
}
.drop-down .drop-down-box:after {
 display:block;
 content:'';
 border-left:6px solid transparent;
 border-right:6px solid transparent;
 border-bottom:6px solid #fff;
 position:absolute;
 top:-6px;
 left:50%;
 margin-left:-6px
}
.drop-down .drop-down-box .loading {
 padding:0
}
.drop-down .drop-down-box .loading img {
 height:40px
}
.drop-down .scroll-group {
 max-height:400px;
 overflow:auto
}
.drop-down.black .drop-down-box {
 background:rgba(231,232,234,.94);
 -moz-box-shadow:0 1px 1px rgba(0,0,0,.18);
 -webkit-box-shadow:0 1px 1px rgba(0,0,0,.18);
 box-shadow:0 1px 1px rgba(0,0,0,.18)
}
.drop-down.black .drop-down-box>.scroll-group>ul li.sub-title,
.drop-down.black .drop-down-box>ul li.sub-title {
 padding:20px 0 10px 10px
}
.drop-down.black .drop-down-box>.scroll-group>ul li.active>a,
.drop-down.black .drop-down-box>ul li.active>a {
 background:#fff url('/assets/images/list-active.png') no-repeat 94% center!important
}
.drop-down.black .drop-down-box>.scroll-group>ul li a,
.drop-down.black .drop-down-box>ul li a {
 color:#8d8f96
}
.drop-down.black .drop-down-box>.scroll-group>ul li a:hover,
.drop-down.black .drop-down-box>.scroll-group>ul li a.hover,
.drop-down.black .drop-down-box>ul li a:hover,
.drop-down.black .drop-down-box>ul li a.hover {
 background:#e0e1e3;
 color:#717373
}
.drop-down.black .drop-down-box>p {
 display:block;
 padding:15px;
 color:#8d8f96
}
.drop-down.black .drop-down-box>p a {
 font-size:13px!important
}
.drop-down.black .drop-down-box:after {
 border-bottom:6px solid #e7e8ea
}
.drop-down-transaction:hover .drop-down-box {
  display: block !important;
}
</style>
