<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-sm" v-if="data">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Editar cartão de crédito</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="updateCard">
          <div class="modal-body">
            <div class="form-group">
              <div class="row">
                <div class="col-md-12">
                  <label>Bandeira / Instituição financeira</label>
                  <CardSelect
                    :default="{ sig: cards[(form.brand_id - 1)] ? cards[(form.brand_id - 1)].sig : 'visa', name: cards[(form.brand_id - 1)] ? cards[(form.brand_id - 1)].name : 'Visa', id: form.brand_id }"
                    class="select"
                    @input="changeBrand($event)"
                  />
                </div>
              </div>
              <div class="row m-top-10">
                <div class="col-md-12">
                  <label>Nome</label>
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    required
                  />
                  <p class="input-info">Informe um nome para identificar seu cartão.</p>
                </div>
              </div>
              <div class="row">
                  <div class="col-md-12">
                    <label>Limite</label>
                    <money v-model="form.card_limit" v-bind="money" class="form-control"></money> 
                  </div>
              </div>
              <div class="row m-top-10">
                <div class="col-md-6">
                  <label>Fecha dia</label>
                  <select
                    v-model="form.invoice_day"
                    type="text"
                    class="form-control"
                    required
                  >
                    <option label="1">1</option>
                    <option label="2">2</option>
                    <option label="3">3</option>
                    <option label="4">4</option>
                    <option label="5">5</option>
                    <option label="6">6</option>
                    <option label="7">7</option>
                    <option label="8">8</option>
                    <option label="9">9</option>
                    <option label="10">10</option>
                    <option label="11">11</option>
                    <option label="12">12</option>
                    <option label="13">13</option>
                    <option label="14">14</option>
                    <option label="15">15</option>
                    <option label="16">16</option>
                    <option label="17">17</option>
                    <option label="18">18</option>
                    <option label="19">19</option>
                    <option label="20">20</option>
                    <option label="21">21</option>
                    <option label="22">22</option>
                    <option label="23">23</option>
                    <option label="24">24</option>
                    <option label="25">25</option>
                    <option label="26">26</option>
                    <option label="27">27</option>
                    <option label="28">28</option>
                    <option label="29">29</option>
                    <option label="30">30</option>
                    <option label="31">31</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Vence dia</label>
                  <select
                    v-model="form.payment_day"
                    type="text"
                    class="form-control"
                    required
                  >
                    <option label="1">1</option>
                    <option label="2">2</option>
                    <option label="3">3</option>
                    <option label="4">4</option>
                    <option label="5">5</option>
                    <option label="6">6</option>
                    <option label="7">7</option>
                    <option label="8">8</option>
                    <option label="9">9</option>
                    <option label="10">10</option>
                    <option label="11">11</option>
                    <option label="12">12</option>
                    <option label="13">13</option>
                    <option label="14">14</option>
                    <option label="15">15</option>
                    <option label="16">16</option>
                    <option label="17">17</option>
                    <option label="18">18</option>
                    <option label="19">19</option>
                    <option label="20">20</option>
                    <option label="21">21</option>
                    <option label="22">22</option>
                    <option label="23">23</option>
                    <option label="24">24</option>
                    <option label="25">25</option>
                    <option label="26">26</option>
                    <option label="27">27</option>
                    <option label="28">28</option>
                    <option label="29">29</option>
                    <option label="30">30</option>
                    <option label="31">31</option>
                  </select>
                </div>
              </div>
              <div class="row m-top-10 hide">
                <div class="col-md-12">
                  <hr>
                  <input type="checkbox" v-model="form.gen_bill" class="to-left" id="gen_bill">
                  <label style="margin-top: 10px;" for="gen_bill" class="to-left">Gerar despesa da fatura</label>
                </div>
              </div>
              <div class="row m-top-10">
                <div class="col-md-12">
                  <label>Conta padrão de pagamento</label>
                  <select-input
                    label="name"
                    :reduce="(field) => field.id"
                    v-model="form.account"
                    :options="accounts"
                    :clearable="true"
                  />
                  <a class="font-13 btn-call-side hide"><i class="mdi mdi-plus-circle-outline add-plus"></i> Cadastrar nova conta</a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-success btn-green-shadow"
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
            >
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import CardSelect from '@/components/CardSelect';
import { Money } from 'v-money'
import AppService from '@/services/app.service';
import cards from '../../store/cards';
import SelectInput from '@/components/SelectInput.vue';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    Money,
    CardSelect,
    SelectInput,
  },
  data() {
    return {
      cards: cards,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      form: {
      },
      isSending: false,
      accounts: [],
    };
  },
  created() {
    AppService.getWallets({}).then(
      (response) => {
        this.accounts = response.wallets;
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );
  },
  methods: {
    changeBrand(brand) {
      console.log(brand);
      this.form.brand = brand;
    },
    updateCard() {
      this.isSending = true;
      AppService.updateCard(this.form).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: 'Sucesso',
            content: 'Cartão atualizado com sucesso!',
            type: 'success',
          });
          this.hide();
          this.$emit("save");
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: 'Erro',
            content: 'Erro interno, por favor tente novamente mais tarde',
            type: 'error',
          });
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #ccd2dc !important;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.second-toolbar a {
  visibility: hidden;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}
.modal-body {
  padding: 25px;
}
.popup-icons {
  height: 50px;
}
.popup-icons .zze-icons-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  margin: auto;
}
.popup-icons .zze-icons-center a.zze-logo-banks {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #fff;
  overflow: hidden;
  cursor: default;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.popup-icons .zze-icons-center a.zze-logo-banks img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
</style>
