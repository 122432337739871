import $ from 'jquery';

const modal = {
  props: ['id'],
  data() {
    return {
      isOpening: false,
      isShow: false,
      isBlock: false,
      backdrop: null,
      data: null,
      form: {
        limit: 0
      },
      initialData: {
        limit: 0
      },
    };
  },
  computed: {
    modalId() {
      return this.id;
    },
    classes() {
      return [
        'modal',
        'in',
        {
          show: this.isShow,
        },
      ];
    },
    styles() {
      return {
        display: this.isBlock ? 'block' : 'none',
      };
    },
  },
  mounted() {
    this.$root.$on('show.modal', this.showHandler);
    this.removeBackdrop();
  },
  destroy() {
    this.removeBackdrop();
  },
  methods: {
    show() {
      $('body').addClass('modal-open');
      this.showBackdrop();
      //  document.getElementById('back').style.display = 'block';
      this.isBlock = true;
      setTimeout(() => {
        this.isShow = true;
      }, 150);
    },
    hide(reset = true) {
      this.isShow = false;
      this.isBlock = false;
      if(this.form.limit == 0) {
        $('body').removeClass('modal-open');
      }
      if (reset) {
        this.form = this.initialData;
        this.data = null;
      }
    },
    showBackdrop() {
      console.log(this.modalId);
      if (document.querySelectorAll('#'+this.modalId+' .modal-backdrop').length == 0) {
        let element = document.getElementById(this.modalId);
        this.backdrop = document.createElement('div');
        this.backdrop.className = 'modal-backdrop in';
        element.appendChild(this.backdrop); 
      }
    },
    removeBackdrop(limit = 0) {
      if (limit == 0) {
        if (this.backdrop) {
          this.backdrop.classList.remove('show');
        }
        $('body .modal-backdrop').remove();
        $('.modal-backdrop').removeClass('show');
        $('.modal-backdrop').remove();
        if (this.backdrop) {
          setTimeout(() => {
            this.backdrop.remove();
            this.backdrop = null;
          }, 150);
        }
      } else {
        let backdrops = document.querySelectorAll('.modal-backdrop');
        for (let index = 1; index < backdrops.length; index++) {
          const backdrop = backdrops[index];
          backdrop.remove();
        }
      }
    },

    showHandler(id, data) {
      if(id) {
        if (id === this.modalId) {
          this.data = data;
          this.initialData = JSON.parse(JSON.stringify(this.form));
          if (data) {
            this.form = JSON.parse(JSON.stringify(data));
            if(this.form.limit == undefined) {
              this.form.limit = 0;
            }
          }
          this.isOpening = true;
          this.show();
        }
      }
      console.log('ID', id);
      console.log('Data', data);
      console.log('This Id', this.modalId);
    },
  },
};

export default modal;
